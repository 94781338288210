<template>
  <v-card>
    <v-card-title>
      <p color="primary">
        Molecule List
      </p>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          offset-md="8"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :loading="loading"
      :headers="totalsPerMoleculeHeaders"
      :items="totalsPerMoleculeData"
      show-select
      item-key="group_series_1-group"
      class="table-rounded"
      sort-by="value"
      sort-desc
      :search="search"
    >
      <template
        v-for="header in totalsPerMoleculeHeaders.filter(header => header.hasOwnProperty('formatter'))"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template #item.group_series_2="{ item }">
        <v-btn
          plain
          @click="searchGroup(item.group_series_2)"
        >
          <v-icon
            color="success"
            medium
            class="me-3"
            @click="searchGroup(item.group_series_2)"
          >
            {{ icons.mdiMagnify }}
          </v-icon>
          <span class="tw-underline">{{ item.group_series_2 }}</span>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import { totalsPerMoleculeHeaders } from '@/assets/headers/index'
import { apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'TotalsPerMolecule',
  setup() {
    const search = ref('')
    const filterStore = useFilterStore()

    return {
      filterStore,
      search,
    }
  },
  data() {
    return {
      totalsPerMoleculeHeaders,
      totalsPerMoleculeData: [],
      loading: false,
      icons: {
        mdiMagnify,
      },
    }
  },
  destroyed() {
    this.filterStoreUnsubscribe()
  },
  async mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    await this.getMongoChartData()
  },
  methods: {
    async getMongoChartData() {
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        // chartType: 'stackedColumnChart',
      }
      this.totalsPerMoleculeData = await apiService.getData(token, 'totalsPerMolecule', body)
      this.loading = false
    },
    searchGroup(group) {
      this.search = group
    },
  },
}
</script>
