import { currencyConvert } from '@/util/functions'

const moleculePerMonthTableHeaders = [
  { text: 'Pharmacy', value: 'pharmacy' },
  { text: 'Generic Name', value: 'genericName' },
  {
    text: 'Generic Potential',
    value: 'value',
    formatter(value) {
      return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
    },
  },
  { text: 'Quantity', value: 'qtyDispensed' },
]

export default moleculePerMonthTableHeaders
