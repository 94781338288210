import { currencyConvert } from '@/util/functions'

const moleculePerMonthTableHeaders = [
  { text: 'Pharmacy', value: 'group_series_0' },
  { text: 'Generic Name', value: 'group_series_5' },
  { text: 'Group Code', value: 'group_series_2', align: 'right' },
  {
    text: 'Unit Price',
    value: 'group_series_3',
    formatter(value) {
      return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
    },
  },
  { text: 'Mfr', value: 'group_series_4' },
  {
    text: 'Generic Potential',
    value: 'value',
    formatter(value) {
      return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
    },
  },
]

export default moleculePerMonthTableHeaders
