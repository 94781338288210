<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <totals-per-molecule-table>   </totals-per-molecule-table>
    </v-col>
  </v-row>
</template>

<script>
import totalsPerMoleculeTable from '@/components/tables/totalsPerMoleculeTable.vue'

export default {
  components: {
    totalsPerMoleculeTable,
  },
  setup() {
    return {}
  },
}
</script>
