const patientTableHeaders = [
  { text: 'Start Date', value: 'payload.startedDateTime' },
  { text: 'Patient Id', value: 'patientID' },
  { text: 'Gender', value: 'Gender' },
  { text: 'Insurance', value: 'insurance' },
  { text: 'Patient Signed', value: 'payload.timeSignedByPatient' },
  { text: 'Doctor Signed', value: 'payload.timeSignedByDoctor' },
  { text: 'Insurance Reply', value: 'payload.insuranceReplyDate' },
  { text: 'Insurance Result', value: 'payload.insuranceResult' },

//   {
//     text: 'example',
//     value: 'examplevalue',
//     formatter(value) {
//       return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
//     },
//   },
]

export default patientTableHeaders
